import React from "react";
import { Link } from "react-router-dom";

import { PathologistCaseResult } from "../../schemas/ApiSchema";
import AmendmentRequiredTag from "./AmendmentRequiredTag";
import CaseCardInfo from "./CaseCardInfo";
import DateReceivedTag from "./DateReceivedTag";
import MicroReportLink from "./MicroReportLink";

interface CaseCardProps {
  caseSummary: PathologistCaseResult;
}

const CaseCard = ({ caseSummary }: CaseCardProps): JSX.Element => {
  const getCaseUrl = (labNumber: string, limsCaseId: string): string => {
    return "/?id=" + btoa(`{"LabNumber":"${labNumber}","CaseID":"${limsCaseId}"}`);
  };

  const {
    labNumber,
    limsCaseId,
    dateReceived,
    reportingPathologist,
    userGroupName,
    amendmentRequired,
  } = caseSummary;
  return (
    <li key={labNumber} className="cy-case-card" data-testid={`${labNumber}CaseCard`}>
      <Link to={getCaseUrl(labNumber, limsCaseId)} className="cy-case-card__content">
        <div className="columns is-mobile">
          <div className="column">
            <h4 className="title is-5">{labNumber}</h4>
          </div>
          <div className="tags" data-testid={`${labNumber}CaseCardTags`}>
            {amendmentRequired && <AmendmentRequiredTag />}
            <DateReceivedTag dateReceived={dateReceived} />
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-4">
            <CaseCardInfo label={"User group"} value={userGroupName} />
          </div>
          <div className="column is-4">
            <CaseCardInfo label={"Reporting pathologist"} value={reportingPathologist} />
          </div>
        </div>
      </Link>
      <MicroReportLink
        labNumber={labNumber}
        limsCaseId={limsCaseId}
        getCaseUrl={getCaseUrl}
      />
    </li>
  );
};

export default CaseCard;
