import * as Sentry from "@sentry/browser";
import { debounce, isEmpty, merge } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { AutosaveStatus } from "../components/atoms/AutosaveIndicator";
import CaseNotFoundDialog from "../components/dialogs/CaseNotFoundDialog";
import SaveReportDialog from "../components/dialogs/SaveReportDialog";
import UserNotAuthorisedDialog from "../components/dialogs/UserNotAuthorisedDialog";
import LoadingBackdrop from "../components/navigation/LoadingBackdrop";
import PageHeader from "../components/pages/PageHeader";
import MicroQuestions from "../components/questions/MicroQuestions";
import { ONE_SECOND_MILLIS } from "../helpers/numbers";
import { SITE_TITLE } from "../helpers/strings";
import { isValidCaseId, isValidLabNumber } from "../helpers/validators";
import { GetCaseResult } from "../schemas/ApiSchema";
import { dataService } from "../services/data.service";
import { RootState } from "../store";
import { KnownBarretts, P53Stain } from "../types/answers";
import { IFormContents } from "../types/builder";
import { AllowedSlides, AuthorisedMicroReport, CaseType, ICaseData } from "../types/case";
import ReadOnlyMode from "../views/ReadOnlyMode";

const ReportBuilder = (): JSX.Element => {
  // Redux
  const { currentUser } = useSelector((state: RootState) => state.auth);

  // Query string
  const [params] = useSearchParams();

  // Local state
  const [autosaveStatus, setAutosaveStatus] = useState<AutosaveStatus>(
    AutosaveStatus.SAVED
  );
  const [busy, setBusy] = useState<boolean>(true);
  const [caseId, setCaseId] = useState<string>("");
  const [internalCaseId, setInternalCaseId] = useState<string>("");
  const [labNumber, setLabNumber] = useState<string>("");
  const [currentVersionId, setCurrentVersionId] = useState<string>();
  const [awaitingNewVersionId, setAwaitingNewVersionId] = useState<boolean>(false);
  const [caseData, setCaseData] = useState<ICaseData>();
  const [caseType, setCaseType] = useState<CaseType | null>(null);
  const [canEditMicro, setCanEditMicro] = useState<boolean>(true);
  const [canAuthoriseMicro, setCanAuthoriseMicro] = useState<boolean>(true);
  const [micro, setMicro] = useState<IFormContents>({
    reasonForAmendment: undefined,
    knownBarretts: undefined,
    adequateSample: undefined,
    // Older PathKit reports which didn't ask this question all had p53 stains,
    // so pre-select 'Yes' for those cases to ensure that the related questions
    // are visible. This is also the correct default for new reports.
    hasP53Stain: P53Stain.YES,
    inadequateSampleReason: undefined,
    pragueCircumferential: "",
    pragueMaximal: "",
    squamousAbnormality: undefined,
    squamousAtypia: undefined,
    squamousP53Staining: undefined,
    columnarGroups: undefined,
    tff3Staining: undefined,
    tff3PositiveGroupsSlideSectionOne: "",
    tff3PositiveGroupsSlideSectionTwo: "",
    columnarAtypia: undefined,
    columnarP53Staining: undefined,
    otherAbnormalities: [],
  });
  const [authorisedReports, setAuthorisedReports] = useState<AuthorisedMicroReport[]>([]);
  const [showCaseNotFoundDialog, setShowCaseNotFoundDialog] = useState<boolean>(false);
  const [showSaveReportDialog, setShowSaveReportDialog] = useState<boolean>(false);
  const [showUserNotAuthorisedDialog, setShowUserNotAuthorisedDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (encodedIdParameter) {
      try {
        const decodedIdParameter: string = atob(encodedIdParameter);
        const { CaseID, LabNumber }: { CaseID: string; LabNumber: string } =
          JSON.parse(decodedIdParameter);
        if (isValidCaseId(CaseID) && isValidLabNumber(LabNumber)) {
          setCaseId(CaseID);
          setLabNumber(LabNumber);
          fetchCase(LabNumber);
        } else {
          throw new RangeError(`Invalid CaseID '${CaseID}' or LabNumber '${LabNumber}'`);
        }
      } catch (error: unknown) {
        // We want to know who's used a link with a broken ID
        Sentry.captureException(error, { user: currentUser });
        setBusy(false);
        setShowCaseNotFoundDialog(true);
      }
    } else {
      setBusy(false);
      setShowCaseNotFoundDialog(true);
    }
  }, []);

  useEffect(() => {
    // It's helpful to show the lab (CYT) number in the browser tab
    document.title = `${labNumber ? `${labNumber} | ` : ""}${SITE_TITLE}`;
  }, [labNumber]);

  useEffect(() => {
    if (!canEditMicro) return;
    setAutosaveStatus(AutosaveStatus.PENDING);
    // Changes to any answers (micro) trigger an autosave request, *except* when
    // we're already waiting for a pending request to return a new versionId...
    if (!awaitingNewVersionId && autosaveStatus !== AutosaveStatus.SAVING) {
      debouncedSaveAnswers();
      return () => debouncedSaveAnswers.cancel();
    } else {
      // ...and in that case, set a flag to watch in the useEffect below which
      // will trigger an autosave request when we've received the new versionId.
      setAwaitingNewVersionId(true);
    }
  }, [micro]);

  useEffect(() => {
    // Triggered only when this *changes* from true to false, i.e. answers were
    // changed *during* an existing autosave request (see previous useEffect).
    if (!testMode && awaitingNewVersionId === false) {
      saveAnswers();
    }
  }, [awaitingNewVersionId]);

  // PathKit runs in 'test mode' when lab number and/or case ID aren't present
  const testMode: boolean = !labNumber || !caseId;

  // If the case has any previously-authorised reports then PathKit expects the
  // user to submit an amended report. PathKit does not simulate the amendment
  // flow in test mode.
  const isMicroAmendment = !testMode && canEditMicro && !isEmpty(authorisedReports);

  // Outbound links from the LIMS include a base64-encoded 'id' query
  // string parameter which includes a lab (CYT) number and case ID.
  const encodedIdParameter: string = params.get("id") ?? "";

  const fetchCase = async (labNumber: string) => {
    const response = await dataService.getCase(labNumber);
    if (response.data) {
      // Merge any inferred and saved answers into local state
      const answers = mergeInferredAndSavedAnswers(response.data);
      setMicro(answers);
      setAutosaveStatus(AutosaveStatus.SAVED);
      setAuthorisedReports(response.data.authorisedMicroReports);
      setCaseData(response.data.caseData);
      setCaseType(response.data.caseType);
      setCanEditMicro(response.data.canEditMicro);
      setCanAuthoriseMicro(response.data.canAuthoriseMicro);
      setCurrentVersionId(response.data.caseVersionId);
      setInternalCaseId(response.data.internalCaseId);
    } else if (response.error.status === 401) {
      // User may not be in the necessary "pathologists" Cognito user group
      setShowUserNotAuthorisedDialog(true);
    } else {
      // Case doesn't exist in the LIMS (404), or it was already authorised (409)
      // or closed (400), so throw away the identifiers which were parsed
      // out of the 'id' query string parameter and show an error.
      setCaseId("");
      setLabNumber("");
      setShowCaseNotFoundDialog(true);
    }
    setBusy(false);
  };

  const mergeInferredAndSavedAnswers = (response: GetCaseResult): IFormContents => {
    return merge(
      // 1. Start with empty/default answers in state
      micro,
      // 2. Try to infer answers from caseType and caseData (if available)
      inferBarrettsHistory(response.caseType),
      inferP53Staining(response.caseData?.slides),
      // 3. Finally give highest priority to any previously-saved answers
      response.micro
    );
  };

  const inferBarrettsHistory = (caseType: CaseType | null): IFormContents => {
    return {
      ...(caseType === CaseType.REFLUX && { knownBarretts: KnownBarretts.NO }),
      ...(caseType === CaseType.SURVEILLANCE && {
        knownBarretts: KnownBarretts.YES,
      }),
    };
  };

  const inferP53Staining = (slides?: AllowedSlides[]): IFormContents => {
    if (slides) {
      return {
        ...(slides.includes("P53")
          ? { hasP53Stain: P53Stain.YES }
          : { hasP53Stain: P53Stain.NO }),
      };
    }
    // Don't infer an answer if case data is missing or lacks slides
    return {};
  };

  // Used to save answers incrementally, but not more than once per second
  const debouncedSaveAnswers = debounce(
    async () => await saveAnswers(),
    ONE_SECOND_MILLIS
  );
  const saveAnswers = async (): Promise<void> => {
    if (!testMode && canEditMicro) {
      setAutosaveStatus(AutosaveStatus.SAVING);
      const response = await dataService.saveMicroReport(
        labNumber,
        caseId,
        micro,
        "AUTOSAVE",
        currentVersionId
      );
      if (response.data) {
        setAutosaveStatus(AutosaveStatus.SAVED);
        setCurrentVersionId(response.data.versionId);
        setAwaitingNewVersionId(false);
      } else if (response.error.status === 409 && window.confirm(response.error.msg)) {
        // Case was modified elsewhere since page load, so reload the page to
        // discard all unsaved answers and fetch the latest version
        location.reload();
      } else {
        // Other autosave errors should be transient, so only update the indicator
        setAutosaveStatus(AutosaveStatus.ERROR);
      }
    }
  };

  const setReadOnlyOnAuthorisation = (authorisedReport: AuthorisedMicroReport): void => {
    setAuthorisedReports([authorisedReport, ...authorisedReports]);
    setCanEditMicro(false);
  };

  if (busy) {
    return <LoadingBackdrop open title={`Loading ${labNumber}`} />;
  }

  return (
    <>
      <div className="container is-max-widescreen">
        <PageHeader
          title={labNumber ? `Case ${labNumber}` : "PathKit"}
          subtitle={caseType ?? "Micro report builder"}
        />
        {canEditMicro ? (
          <MicroQuestions
            answers={micro}
            autosaveStatus={!testMode ? autosaveStatus : undefined}
            caseData={caseData}
            caseType={caseType}
            canEditMicro={canEditMicro}
            authorisedReports={authorisedReports}
            isMicroAmendment={isMicroAmendment}
            internalCaseId={internalCaseId}
            setAnswers={setMicro}
            setShowSaveReportDialog={setShowSaveReportDialog}
          />
        ) : (
          <ReadOnlyMode
            caseData={caseData}
            caseType={caseType}
            authorisedReports={authorisedReports}
            internalCaseId={internalCaseId}
          />
        )}
      </div>

      {showCaseNotFoundDialog && (
        <CaseNotFoundDialog
          caseNotFound={!!encodedIdParameter}
          setShowCaseNotFoundDialog={setShowCaseNotFoundDialog}
        />
      )}
      {showUserNotAuthorisedDialog && <UserNotAuthorisedDialog />}
      {showSaveReportDialog && (
        <SaveReportDialog
          testMode={testMode}
          labNumber={labNumber}
          caseId={caseId}
          answers={micro}
          canAuthoriseMicro={canAuthoriseMicro}
          previousVersionId={currentVersionId}
          setNextVersionId={setCurrentVersionId}
          setShowSaveReportDialog={setShowSaveReportDialog}
          setReadOnlyOnAuthorisation={setReadOnlyOnAuthorisation}
        />
      )}
    </>
  );
};

export default ReportBuilder;
