import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { CaseType, ICaseData } from "../../types/case";
import Accordion from "../common/Accordion";
import RequestSpecialDialog from "../dialogs/RequestSpecialDialog";
import SlideLinks from "./SlideLinks";

export const TEST_ID_SHOW_REQUEST_SPECIAL_DIALOG_BUTTON =
  "ShowRequestSpecialDialogButton";

interface LabWorkProps {
  caseData?: ICaseData;
  caseType: CaseType | null;
  canEditMicro: boolean;
  internalCaseId: string;
  expanded?: boolean;
}

const LabWork = ({
  caseData,
  caseType,
  canEditMicro,
  internalCaseId,
  expanded = false,
}: LabWorkProps): JSX.Element | null => {
  const [showRequestSpecialDialog, setShowRequestSpecialDialog] =
    useState<boolean>(false);

  if (!caseData || !caseType) return null;
  return (
    <Accordion expanded={expanded} title="Laboratory work" id="labWork">
      <div
        className="columns is-multiline is-variable is-5"
        style={{ fontSize: "0.875rem" }}
      >
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <SlideLinks labNumber={caseData.labNumber} />
        </div>
        {canEditMicro && (
          <div className="column is-half-tablet is-full-desktop is-half-widescreen">
            <section className="mb-4">
              <h4 className="title is-6 mb-4">Specials</h4>
              <button
                className="button is-link is-light is-size-7"
                onClick={() => setShowRequestSpecialDialog(true)}
                data-testid={TEST_ID_SHOW_REQUEST_SPECIAL_DIALOG_BUTTON}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Request special
              </button>
            </section>
          </div>
        )}
      </div>
      {showRequestSpecialDialog && (
        <RequestSpecialDialog
          setShowRequestSpecialDialog={setShowRequestSpecialDialog}
          internalCaseId={internalCaseId}
        />
      )}
    </Accordion>
  );
};

export default LabWork;
