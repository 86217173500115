import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_AMENDMENT_REQUIRED_TAG = "AmendmentRequiredTag";

const AmendmentRequiredTag = (): JSX.Element => {
  return (
    <span className="tag is-rounded is-link" data-testid={TEST_ID_AMENDMENT_REQUIRED_TAG}>
      <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
      Amendment required
    </span>
  );
};

export default AmendmentRequiredTag;
