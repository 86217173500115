import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

interface MicroReportLinkProps {
  labNumber: string;
  limsCaseId: string;
  getCaseUrl: (labNumber: string, limsCaseId: string) => string;
}

const MicroReportLink = ({
  labNumber,
  limsCaseId,
  getCaseUrl,
}: MicroReportLinkProps): JSX.Element => {
  return (
    <Link
      to={getCaseUrl(labNumber, limsCaseId)}
      className="button is-ghost is-small pb-5 pr-5 is-flex is-justify-content-flex-end"
      data-testid={`${labNumber}OpenMicroReportLink`}
    >
      <FontAwesomeIcon icon={faEdit} className="mr-2" /> Open micro report
    </Link>
  );
};

export default MicroReportLink;
