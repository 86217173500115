import classNames from "classnames";
import React, { useEffect, useState } from "react";

import SortIndicator from "../components/atoms/SortIndicator";
import CaseCard from "../components/cases/CaseCard";
import LoadingSpinner from "../components/common/LoadingSpinner";
import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";
import { PathologistCaseResult } from "../schemas/ApiSchema";
import { dataService } from "../services/data.service";

export const TEST_ID_CASE_LIST_OPEN_CASES_TAB = "CaseListOpenCasesTab";
export const TEST_ID_CASE_LIST_CLOSED_CASES_TAB = "CaseListClosedCasesTab";
export const TEST_ID_CASE_LIST_CASE_COUNT_HEADING = "CaseListCaseCount";

export type CaseStateTabs = "OPEN" | "CLOSED";

const CaseList = (): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(true);
  const [cases, setCases] = useState<PathologistCaseResult[]>();
  const [totalCases, setTotalCases] = useState<Record<CaseStateTabs, number>>({
    OPEN: 0,
    CLOSED: 0,
  });
  const [activeTab, setActiveTab] = useState<CaseStateTabs>("OPEN");

  const pageTitle = "Cases";

  useEffect(() => {
    fetchCases(activeTab);
    document.title = `${pageTitle} | ${SITE_TITLE}`;
  }, [activeTab]);

  const fetchTotalOpenCases = async (): Promise<number> => {
    const response = await dataService.getPathologistCases("open");
    if (!response.data) {
      return 0;
    } else {
      return response.data?.totalCases;
    }
  };

  const fetchCases = async (activeTab: CaseStateTabs) => {
    setBusy(true);
    const response = await dataService.getPathologistCases(activeTab.toLowerCase());
    if (response.data) {
      setCases(response.data.cases);
      if (activeTab === "OPEN") {
        setTotalCases({ ...totalCases, OPEN: response.data.totalCases });
      }
      if (activeTab === "CLOSED") {
        setTotalCases({
          OPEN: await fetchTotalOpenCases(),
          CLOSED: response.data.totalCases,
        });
      }
    }
    setBusy(false);
    return response.data;
  };

  const handleChangeTab = (status: CaseStateTabs, e: React.MouseEvent) => {
    e.preventDefault();
    setActiveTab(status);
  };

  return (
    <div className="container is-max-widescreen">
      <PageHeader
        title="Cases"
        subtitle={activeTab === "OPEN" ? "Open cases" : "Closed cases"}
      />
      <div className="columns is-variable is-4 is-justify-content-flex-start">
        <div className="column is-one-quarter">
          <div className="menu pr-6">
            <p className="menu-label">Cases</p>
            <ul className="menu-list">
              <li onClick={(e) => handleChangeTab("OPEN", e)} key="OPEN">
                <a
                  className={classNames({
                    "is-active has-background-primary": activeTab === "OPEN",
                  })}
                  data-testid={TEST_ID_CASE_LIST_OPEN_CASES_TAB}
                >
                  {`Open cases (${totalCases["OPEN"] ?? 0})`}
                </a>
              </li>
              <li onClick={(e) => handleChangeTab("CLOSED", e)} key="CLOSED">
                <a
                  className={classNames({
                    "is-active has-background-primary": activeTab === "CLOSED",
                  })}
                  data-testid={TEST_ID_CASE_LIST_CLOSED_CASES_TAB}
                >
                  Closed cases
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="column is-two-quarters pt-2">
          {busy && <LoadingSpinner />}
          {!busy && !!cases && cases.length > 0 && (
            <>
              <div className="is-flex is-justify-content-space-between is-align-items-baseline">
                <h3
                  className="title is-size-4"
                  data-testid={TEST_ID_CASE_LIST_CASE_COUNT_HEADING}
                >{`${totalCases[activeTab]} cases`}</h3>
                <SortIndicator activeTab={activeTab} />
              </div>
              <ul className="mb-4" data-testid={`${activeTab.toLowerCase()}CaseList`}>
                {cases.map((caseSummary) => (
                  <CaseCard key={caseSummary.labNumber} caseSummary={caseSummary} />
                ))}
              </ul>
            </>
          )}

          {!busy && (!cases || cases.length === 0) && (
            <h3 className="title is-size-4">No cases found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseList;
