import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { dataService } from "../../services/data.service";

export const TEST_ID_SPECIAL_BLOCK_INPUT = "SpecialBlockInput";
export const TEST_ID_SPECIAL_BLOCK_VALIDATION_ERROR = "SpecialBlockValidationError";
export const TEST_ID_SPECIAL_REQUEST_DIALOG = "SpecialRequestDialog";
export const TEST_ID_SPECIAL_REQUEST_DETAILS = "SpecialRequestDetails";
export const TEST_ID_SPECIAL_REQUEST_DETAILS_VALIDATION_ERROR =
  "SpecialRequestDetailsValidationError";
export const TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_CHECKBOX =
  "SpecialCustomRequestDetailsCheckbox";
export const TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_INPUT =
  "SpecialCustomRequestDetailsInput";
export const TEST_ID_SPECIAL_SUBMIT = "SpecialSubmit";
export const TEST_ID_SPECIAL_CANCEL = "SpecialCancel";

interface RequestSpecialDialogProps {
  setShowRequestSpecialDialog: (x: boolean) => void;
  internalCaseId: string;
}

const RequestSpecialDialog = ({
  setShowRequestSpecialDialog,
  internalCaseId,
}: RequestSpecialDialogProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [showCustomRequestInput, setShowCustomRequestInput] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const { block, requestDetails, customRequestInput } = watch();

  const onSubmit = async (): Promise<void> => {
    setBusy(true);
    setError("");
    const response = await dataService.createSpecial(
      internalCaseId,
      block,
      customRequestInput ?? requestDetails
    );
    if (response.error) {
      setError(response.error.msg);
    }
    if (response.data) {
      setShowRequestSpecialDialog(false);
      enqueueSnackbar("Special requested successfully", { variant: "success" });
    }
    setBusy(false);
  };

  const BlockInputField = (): JSX.Element => {
    return (
      <div className="field">
        <label className="label mr-4">Block</label>
        <input
          className={classNames("input control", { "is-danger": errors.block })}
          style={{ maxWidth: 90 }}
          type="text"
          autoFocus
          placeholder="E.g. A1"
          data-testid={TEST_ID_SPECIAL_BLOCK_INPUT}
          {...register("block", {
            onChange: (e) => {
              setValue("block", e.target.value.toUpperCase());
            },
            required: true,
            minLength: 2,
            maxLength: 2,
            pattern: /^[A-Z][0-9]$/,
          })}
        />
        {errors.block && (
          <div
            className="has-text-danger"
            data-testid={TEST_ID_SPECIAL_BLOCK_VALIDATION_ERROR}
          >
            Please enter a valid block
          </div>
        )}
      </div>
    );
  };

  const RequestDetailsRadioGroup = (): JSX.Element => {
    return (
      <div className="field">
        <label className="label mt-4">Request details</label>
        <div className="radios">
          {["Re-scan", "Re-spin", "p53", "ALOX15"].map((requestDetailsOption) => {
            return (
              <label className="radio mr-2" key={requestDetailsOption}>
                <input
                  type="radio"
                  className="mr-2"
                  value={requestDetailsOption}
                  data-testid={TEST_ID_SPECIAL_REQUEST_DETAILS + requestDetailsOption}
                  {...register("requestDetails", {
                    onChange: () => {
                      setShowCustomRequestInput(false);
                      setValue("customRequestInput", undefined);
                    },
                    required: !showCustomRequestInput,
                  })}
                />
                {requestDetailsOption}
              </label>
            );
          })}
        </div>
      </div>
    );
  };

  const CustomRequestInputCheckbox = (): JSX.Element => {
    return (
      <label>
        <input
          className="mr-2"
          type="checkbox"
          checked={showCustomRequestInput}
          onChange={(e) => {
            setShowCustomRequestInput(e.target.checked);
            setValue("requestDetails", undefined);
          }}
          data-testid={TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_CHECKBOX}
        />
        I would like to make a custom request
      </label>
    );
  };

  const CustomRequestInputField = (): JSX.Element => {
    return (
      <div className="field">
        <p className="control mt-3 mb-0">
          <textarea
            className={classNames("textarea", {
              "is-danger": errors.customRequestInput,
            })}
            autoFocus
            style={{ fontSize: "1rem" }}
            rows={1}
            placeholder="Select from the options above or write a custom request"
            maxLength={250}
            data-testid={TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_INPUT}
            {...register("customRequestInput", {
              onChange: (e) => {
                setValue("customRequestInput", e.target.value);
                setValue("requestDetails", undefined);
              },
              required: showCustomRequestInput,
            })}
          ></textarea>
        </p>
      </div>
    );
  };

  const RequestDetailsValidationError = (): JSX.Element => {
    return (
      <div
        className="has-text-danger"
        data-testid={TEST_ID_SPECIAL_REQUEST_DETAILS_VALIDATION_ERROR}
      >
        Please include details about your request
      </div>
    );
  };

  return (
    <div className="modal is-active" data-testid={TEST_ID_SPECIAL_REQUEST_DIALOG}>
      <div className="modal-background"></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <form
          className="box content has-background-grey-lighter"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="title is-4">Request special</h3>
          <BlockInputField />
          <RequestDetailsRadioGroup />
          <CustomRequestInputCheckbox />

          {showCustomRequestInput === true && <CustomRequestInputField />}
          {(errors.requestDetails || errors.customRequestInput) && (
            <RequestDetailsValidationError />
          )}

          <div className="field is-grouped mt-5">
            <div className="control buttons">
              <button
                type="submit"
                value="Submit"
                className={classNames("button is-primary", { "is-loading": busy })}
                data-testid={TEST_ID_SPECIAL_SUBMIT}
              >
                Submit
              </button>
              <input
                type="reset"
                value="Cancel"
                className="button is-secondary is-light"
                onClick={() => setShowRequestSpecialDialog(false)}
                data-testid={TEST_ID_SPECIAL_CANCEL}
              />
            </div>
          </div>
          {!!error && <p className="notification is-danger">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default RequestSpecialDialog;
