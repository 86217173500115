import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CaseStateTabs } from "src/routes/CaseList";

interface SortIndicatorProps {
  activeTab: CaseStateTabs;
}

const SortIndicator = ({ activeTab }: SortIndicatorProps): JSX.Element => {
  return (
    <p className="column is-size-7 is-italic has-text-right">
      <FontAwesomeIcon icon={faArrowDownWideShort} className="mr-1" />
      {activeTab === "CLOSED" ? "Most recently closed" : "Oldest cases first"}
    </p>
  );
};

export default SortIndicator;
