import { format } from "date-fns";
import React from "react";

export const TEST_ID_DATE_RECEIVED_TAG = "DateReceivedTag";

interface DateReceivedTagProps {
  dateReceived: string;
}

const DateReceivedTag = ({ dateReceived }: DateReceivedTagProps): JSX.Element => {
  return (
    <span className="tag is-rounded" data-testid={TEST_ID_DATE_RECEIVED_TAG}>
      {"Received " + format(dateReceived, "d MMMM yyyy")}
    </span>
  );
};

export default DateReceivedTag;
